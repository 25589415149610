<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="pt-8" title="ABL Friction Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="ABL Friction Machine Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/ABLFrictionDetailed-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/ABLFrictionDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing friction sensitivity tests is to measure
              the sensitiveness of a material to friction insults. The
              transition from no reaction to reaction must be determined to
              evaluate in-process risks. The in-process potential from friction
              scenarios is compared against the material response. For example
              below is shown a plot of an example transition from no-reaction at
              low frictional forces (per area) to reaction at increasing
              frictional forces. The frictional force must be expressed in terms
              of force per area at a given speed in order to compare to
              in-process scenarios and in-process frictional areas and speeds.
            </p>
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/scurve-abl-min.png')"
            >
              <img src="@/assets/IPSeries/scurve-abl-min.png" />
            </a>
            <TitleComponent
              class="pt-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              There are multiple pieces of friction equipment. The test
              equipment should be able to closely mimic in-process conditions
              (surface types and roughness) and relate to the in-process
              frictional forces by the expression of reaction probability
              in-terms of friction forces per area at a given speed. The ABL
              Friction machine has been used by Hercules, ATK, SMS, and others
              to closely mimic in-process conditions and obtain reaction
              probabilities at various frictional forces per area at various
              speeds for more than 25 years.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Pressure applied to sample
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Used to relate sensitivity data (psi) to in-process
                      pressures (N/m2)
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Pressures used to mimic process equipment conditions
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Pressure range on the hydraulic ram from 25-1000 psi
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Slider or Anvil speed
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a uniform and repeatable speed for each trial that
                      can be related to in-process conditions of the friction
                      insult
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Other speeds may be used to simulate specific process
                      equipment conditions
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Typically up to 8 ft/sec (2.4 m/sec)
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">Hydraulic ram</td>
                    <td class="border px-4 py-2 font-medium">
                      Consistent loading of sample beneath the stationary wheel
                      throughout the test
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Ram diameter is typically 1 inch
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Thickness of friction wheel
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Strongly influences the amount of pressure applied to the
                      sample; must be of sufficient thickness to resist the test
                      loads without structural deformation
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Other thicknesses may be used to simulate specific process
                      equipment components
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to 0.125 ± 0.001 in (0.318 cm)
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Surface finish of friction wheel and anvil
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a consistent friction insult per area
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      MIL-STD-1751A Method 1021 references 63 µin for a surface
                      finish for the ABL Friction test surfaces
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to 1.3 – 1.8 µm (50 – 70 µin) for at least
                      six (friction wheel) or three (friction anvil) random
                      locations on at least one friction wheel or two friction
                      anvils from each grinding group.
                      <router-link
                        class="link"
                        to="/library/equipment-standards-procedures"
                        >There are standardized ABL Friction Wheel and Anvil
                        Surfacing Procedures</router-link
                      >. Other surface finishes may be used to simulate specific
                      process equipment components.
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Material and hardness of friction wheel and anvil
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a standardized material and hardness
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Material and hardness of common process equipment tooling;
                      other materials or hardnesses may be used to simulate
                      specific process equipment components
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized as MGR tool steel (A8) with a hardness of
                      Rockwell “C” 55 – 62
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Parallelism of friction wheel and anvil
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a flat, even contact area for a uniform friction
                      insult that can be related to in-process conditions
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      A contact pattern other than square could result in a
                      localized high friction insult being applied to the sample
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Friction loads less than 25 psi can result in uneven
                      contact
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Flatness of the friction anvil
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a uniform surface so additional friction forces
                      are not introduced by surface inconsistencies
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to ≤0.002 in
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Sample thickness
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Replicate an in-process condition or otherwise repeatably
                      test a standardize thickness
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Other sample thicknesses may be used to simulate specific
                      process equipment conditions
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to a monolayer of material; thickness of
                      slices are typically 0.033 ± 0.004 in
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Sample quantity or length
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide sufficient sample to cover the area directly under
                      the wheel
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Amount of sample on the anvil standardized to cover
                      approximately an area 0.25-in long by at least 0.125-in
                      wide starting under the wheel
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Test Temperature and Humidity
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Determine the temperature/ humidity dependence of the
                      sample’s sensitivity as applicable. Energetics typically
                      become more sensitive with an increase in temperature.
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized as ambient temperature and humidity.
                      In-process conditions should be used as possible.
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">Slide length</td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a consistent and repeatable friction insult
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to 1 ± 0.05 in
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Slider tightness
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Minimal lateral movement and sliding friction (no binding)
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to a tightness of fit ≤0.040 in
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Reaction detection
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Observation, detection, and documentation of a material’s
                      response to the test stimuli
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      High-speed video, gas analyzer
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Characterization of reaction types
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide consistent standards for judgment and evaluation
                      of a material
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Flash/ flame, audible report, smoke, flame trace,
                      significant sample consumption, decomposition (change in
                      color or odor), jetting (particulate velocities &gt; 1000
                      in/sec)
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Number of trials
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Adequately determine the sensitivity result
                      (characterization curve) with the fewest number of trials
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      At least 30 trials
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Sample condition
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Repeatably obtain results for a given sample
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Substances are tested in the worst-case or
                      smallest-particle-size in-process condition.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Reaction upon insult
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Detection of explosion (high-speed video, gas analysis,
                      etc.)
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      The trial is considered “+” at the ONSET of material
                      reaction.
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Transition probability
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Transition probability most efficiently defined by use of
                      an algorithm such as SEQ, Neyer, or other step sized
                      optimized method
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Risk is assessed based on the comparison of the in-process
                      frictional force per area to the material response.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent
              class="pt-8"
              title="ABL Friction Setup Example"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/setupabl-min.jpg')"
            >
              <img class="m-auto" src="@/assets/IPSeries/setupabl-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Flash (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166128717?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166128717"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8"
              title="Jetting (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166128719?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166128719"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="No Reaction (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166128720?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166128720"
              allow="autoplay; fullscreen"
              id="player_3"
              name="fitvid2"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
